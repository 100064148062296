<template>
    <div id="Video">

    </div>
</template>

<script>
import Apis from "@/requests/api";
import {FormatDate} from "@/utils/methods";

export default {
    name: "Video",
    props: {
        gameInfo: Object
    },
    mounted() {
        if (!(this.gameInfo && this.gameInfo.contentId)) {
            this.$emit("complete", {});
            return
        }
        this.$http.get(Apis.Game.getVideo, {contentId: this.gameInfo.contentId}).then(res => {
            if (this.gameInfo.contentName){
                document.title = this.gameInfo.contentName
            }
            if (res.status) {
                let vid = "", playauth = "", cover = "", startTime = Date.now();
                if (res.status) {
                    vid = res.data.body.videoMeta.videoId;
                    playauth = res.data.body.playAuth;
                    cover = res.data.body.videoMeta.coverURL;
                }
                if (this.player) {
                    this.player.dispose();
                    this.player.off("showBar");
                    this.player.off("hideBar");
                    this.player.off("canplay");
                    this.player.off("ended");
                }
                this.player = new Aliplayer({
                    "id": "Video",
                    "vid": vid,
                    "playauth": playauth,
                    cover: cover,
                    autoplay: true,
                    "width": "100%",
                    "height": "100%",
                    "isLive": false,
                    "rePlay": false,
                    "playsinline": true,
                    "preload": true,
                    "controlBarVisibility": "hover",
                    "useH5Prism": true,
                    "definition": "FD,LD,SD,HD",
                    "defaultDefinition": "FD",
                    skinLayout: [
                        {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
                        {
                            name: "H5Loading", align: "cc"
                        },
                        {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
                        {name: "infoDisplay"},
                        {name: "tooltip", align: "blabs", x: 0, y: 56},
                        {name: "thumbnail"},
                        {
                            name: "controlBar", align: "blabs", x: 0, y: 0,
                            children: [
                                {name: "progress", align: "blabs", x: 0, y: 44},
                                {name: "playButton", align: "tl", x: 15, y: 12},
                                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                                {name: "fullScreenButton", align: "tr", x: 10, y: 12},
                                //{name: "subtitle", align: "tr", x: 15, y: 12},
                                {name: "setting", align: "tr", x: 15, y: 12},
                                {name: "volume", align: "tr", x: 5, y: 10}
                            ]
                        }
                    ]
                });
                this.player.on("ready", () => {
                    this.$emit("startPlay", this.player);
                });
                this.player.on("play", () => {
                    startTime = Date.now();
                    // this.player.fullscreenService.requestFullScreen()
                });
                this.player.on("ended", () => {
                    this.$emit("endPlay", {
                        startTime: FormatDate(startTime, "YYYY-MM-DD hh:mm:ss"),
                        endTime: FormatDate(new Date().getTime(), "YYYY-MM-DD hh:mm:ss"),
                    });
                });
            } else {
                this.$toast(res.message);
                this.$emit("complete", res);
            }
        });
    }
}
</script>

<style scoped>
#Video {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>
<template>
    <m-loading id="StartGame" :loading="gameLoading" :text="loadingText">
        <Video v-if="gameInfo.contentType == 4" :game-info="gameInfo"
               @startPlay="startPlay" @endPlay="endPlay"/>
        <Game v-else :game-info="gameInfo"
              @loaded="startTime = Date.now()" @proceed="finish"/>
    </m-loading>
</template>

<script>
import Apis from "@/requests/api";
import Game from "./components/Game";
import Video from "./components/Video";
import {GetRequest} from "@/utils/methods"

export default {
    name: "StartGame",
    components: {Game, Video},
    data() {
        return {
            gameLoading: false,
            submitData: false,
            loadingText: "数据提交中...",
            startTime: Date.now(),
            gameInfo: GetRequest(),
            /*gameInfo: {
                "contentSnapshotId": 343622218043653,
                "contentSnapshotGroupId": 343622218043653,
                "contentType": 1,
                "contentId": 329904496046533,
                "contentName": "超级秒表",
                "icon": "https://static.cdn.iquizoo.com/eval3.x/pro/content/evaluationgame/add/200x200%E8%B6%85%E7%BA%A7%E7%A7%92%E8%A1%A8.png",
                "url": "https://static.cdn.iquizoo.com/C2Game/alpha/evaluation/stopwatchPreSchool/1.0.0",
                "coverPlanUri": "https://static.cdn.iquizoo.com/eval3.x/pro/content/evaluationgame/add/%E8%B6%85%E7%BA%A7%E7%A7%92%E8%A1%A8banner3.jpg",
                "description": "超级秒表测查反应力，特别是指个体对外界信号做出快速反应的能力",
                "contentVersion": "1.0.0",
                "isFinished": false,
                "stage": 1,
                "executionsNumber": 1,
                "inFactExecutionsNumber": 0,
                "displayOrder": 1,
                "firstPlay": false,
                "enabled": true,
                "abilitys": [{
                    "name": "反应力",
                    "icon": "https://static.cdn.iquizoo.com/brain/image/question/168232205017937.png",
                    "color": "#b61285"
                }]
            }*/
        }
    },
    created() {
        this.$store.commit("user/setToken", this.gameInfo.token);
        this.$store.commit("user/setUserId", this.gameInfo.userId);
        this.$store.commit("user/setUserName", this.gameInfo.userName);
    },
    methods: {
        finish() {
            window.wx.miniProgram.getEnv(function (res) {
                if (res.miniprogram) {
                    window.wx.miniProgram.navigateBack({
                        delta: 1
                    })
                }
            })
        },
        /**
         * 视频播放结束
         * @param data
         */
        endPlay(data) {
            this.gameLoading = true;
            const params = {
                contentSnapshotId: this.gameInfo.contentSnapshotId,
                ...data
            };
            this.$http.post(Apis.Game.updateCompletionTimes, params).then(res => {
                if (!res.status) {
                    this.$toast("数据提交失败");
                }
                this.gameLoading = false;
            });
        },

        /**
         * 视频初始化完成
         * @param player
         */
        startPlay(player) {
            player.play();
        },
    }
}
</script>

<style lang="less" scoped>
#StartGame {
    width: 100%;
    height: 100%;
}
</style>
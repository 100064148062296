<template>
    <div id="Game">
        <div class="loading">
            <div class="loading-cont">
                <div>
                    <h1 v-if="gameInfo.contentName">即将进入“{{ gameInfo.contentName }}”</h1>
                    <img src="@/assets/game/g_loading.gif" alt="">
                </div>
            </div>
        </div>
        <iframe ref="iframe">

        </iframe>
    </div>
</template>

<script>
export default {
    name: "Game",
    props: {
        gameInfo: Object
    },
    mounted() {
        if (this.gameInfo.contentName) {
            document.title = this.gameInfo.contentName
        }
        window.onmessage = res => {
            const data = res.data;
            console.log(data);
            switch (data.type) {
                // 游戏数据提交失败
                case "sendFail":
                    this.$toast("数据提交失败");
                    this.$emit("proceed");
                    break;
                // 游戏加载完成
                case "gameLoaded":
                    this.$emit("loaded");
                    break;
                // 游戏结束
                case "gameEnded":
                    this.$emit("ended");
                    break;
                // 点击游戏继续
                case "gameContinue":
                    this.$emit("proceed");
                    break;
                // 游戏帧率
                case "frameRate":
                    break;
            }
        };
        const url = "https://cdn.iquizoo.com/static/game-shell/index.html";
        const iframe = this.$refs.iframe;
        const urlData = [];
        const params = {
            url: this.gameInfo.url,
            contentSnapshotId: this.gameInfo.contentSnapshotId,
            contentType: this.gameInfo.contentType,
            contentId: this.gameInfo.contentId,
            contentName: this.gameInfo.contentName,
            contentVersion: this.gameInfo.contentVersion,
            stage: this.gameInfo.stage,
            executionsNumber: this.gameInfo.executionsNumber,
            inFactExecutionsNumber: this.gameInfo.inFactExecutionsNumber,
            firstPlay: this.gameInfo.firstPlay,
            baseUrl: process.env.VUE_APP_API_URL,
            userName: this.$store.getters["user/getUserName"](),
            userId: this.$store.getters["user/getUserId"](),
            token: this.$store.getters["user/getToken"](),
            _showData: this.gameInfo._showData,
        }
        for (let key in params) {
            if (typeof params[key] !== "undefined") {
                urlData.push(key + "=" + encodeURIComponent(params[key]));
            }
        }
        iframe.contentWindow.location.replace(url + "?" + urlData.join("&"));
    }
}
</script>

<style scoped lang="less">
#Game {
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    overflow: hidden;

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("../../../assets/game/g_back.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading-cont {
        width: 70%;
        height: 50%;
        max-width: 600px;
        max-height: 400px;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 1rem;
            margin-bottom: 15px;
        }

        img {
            height: 30vh;
            max-height: 163px;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
}
</style>